body {
  margin: 0;
  padding: 20px;
  font-family: Arial, sans-serif;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
.controls {
  margin-bottom: 20px;
}

#canvas-container {
  width: 1280px;
  height: 720px;
  border: 1px solid #ccc;
  margin: 0 auto;
}

.character-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
  margin: 5px;
}
.sliders-control {
  margin: 12px;
}
.flex-col {
  display: flex;
  flex-direction: row;
}
