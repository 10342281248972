.btn {
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.controls {
  padding: 20px;
  background-color: #e0efed;
  border-radius: 4px;
}

.controls input {
  margin: 5px;
}

.controls button {
  margin: 5px;
}

.controls span {
  margin: 5px;
}

.flex-container {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.flex-container input {
  margin: 5px;
}

.flex-container button {
  margin: 5px;
}

.flex-container span {
  margin: 5px;
}
#editBar {
  border-radius: 4px;
  padding: 6px;
  border: 1px solid black;
}

.outline {
  border-radius: 4px;
  padding: 6px;
  border: 1px solid black;
}

.margin {
  margin: 3px;
}

.padding {
  padding: 3px;
}

.flex-one {
  flex: 1;
}

.character-row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}

.audio-controls {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
}

.audio-row {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.audio-play-btn {
  padding: 4px 12px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.audio-play-btn:hover {
  background-color: #45a049;
}

.audio-label {
  flex: 1;
  font-size: 0.9em;
  color: #666;
}

.control-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.undo-btn {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.undo-btn:hover {
  background-color: #5a6268;
}
