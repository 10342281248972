.outer-modal {
  position: fixed; /* Keep modal fixed in the viewport */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the modal */
  padding: 20px;
  background-color: sandybrown;
  border-radius: 8px;
  box-shadow: 5px 10px #888888;
  min-width: 350px;
  min-height: 550px;
  max-height: 80vh; /* Add max height to prevent overflow */
}

.exit-button {
  margin-right: 2px;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: large;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  position: sticky;
  top: 0;
  z-index: 1001;
  float: right;
}

.inner-modal {
  padding: 16px;
  overflow-y: auto; /* Add scrolling for content */
  max-height: calc(80vh - 60px); /* Allow space for padding and button */
}
