.timeline {
  width: 100%;
  min-height: 100px;
  border: 1px solid #ccc;
  margin: 20px 0;
  position: relative;
  overflow-x: auto;
  cursor: pointer;
  display: flex;
}
.timeline-event {
  position: absolute;
  height: 30px;
  /* border-radius: 4px; */
  cursor: pointer;
  color: white;
  padding: 5px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: opacity 0.2s;
}
.flex {
  display: flex;
}
.timeline-event.move {
  background: #4caf50;
}
.timeline-event.audio {
  background: #ba2a0b;
}
.timeline-event.motion {
  background: #2196f3;
}
.timeline-event.dialog {
  background: #9c27b0;
}
.timeline-playhead {
  position: absolute;
  width: 2px;
  height: 100%;
  background: red;
  top: 0;
  cursor: ew-resize;
  z-index: 100;
}
.timeline-playhead::after {
  content: '';
  position: absolute;
  top: -10px;
  left: -4px;
  width: 10px;
  height: 10px;
  background: red;
  border-radius: 50%;
}
#timeline-json {
  width: 100%;
  height: 500px;
  margin: 10px 0;
  font-family: monospace;
  font-size: 12px;
}
.timeline-event:hover {
  opacity: 0.8;
}
